export type IRecipe = {
    id: number;
    title: string;
    preparation: string;
    status: string;
}
export type IRecipeTableFilters = {
    mealType: string[];
    prepTime: string[];
    difficulty: string[];
    budget: string[];
    calories: string[];
    normalDiet:string[];
};
export type OnFilterChange =  {
    mealType: string[];
    prepTime: string[];
    calories: string[];
    difficulty: string[];
    budget: string[];
    normalDiet:string[];
}
export type IRecipeTableFilterValue = string | string[];


export type OptionType = {
    value: number;
    label: string;
};
export const BudgetOptions: OptionType[] = [
    {
        value: 0,
        label: "BUTTON_BUDGET_CHEAP",
    },
    {
        value: 1,
        label: "BUTTON_BUDGET_NORMAL",
    },
    {
        value: 2,
        label: "BUTTON_BUDGET_HIGH",
    },
];
export const getBudgetLabel = (value: number) => {
    const option = BudgetOptions.find((option1) => option1.value === value);
    return option ? option.label : "";
}

export const DifficultyLevelOptions: OptionType[] = [
    {
        value: 0,
        label: "LABEL_EASY",
    },
    {
        value: 1,
        label: "LABEL_MEDIUM",
    },
    {
        value: 2,
        label: "LABEL_HARD",
    }
];

// export const BudgetOptions: OptionType[] = [
//     {
//         label: "CHEAP",
//         value: '0'
//     },
//     {
//         label: "NORMAL",
//         value: '1'
//     },
//     {
//         label: "HIGH",
//         value: '2'
//     }
// ];
export const getDifficultyLevelLabel = (value: number) => {
    const option = DifficultyLevelOptions.find((option1) => option1.value === value);
    return option ? option.label : "";
}

// export const getBudgetLabel = (value: number) => {
//     const option = BudgetOptions.find((option1) => option1.value === value);
//     return option ? option.label : "";
// }
export const PreparationTimeOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90];

export const MealTypeOptions: OptionType[] = [
    {
        label: "BREAKFAST",
        value: 0,
    },
    {
        label: "LUNCH",
        value: 1,
    },
    {
        label: "DINNER",
        value: -1,
    },
    {
        label: "SNACK",
        value: 2,
    },
    {
        label: "PRE-WORKOUT",
        value: 3,
    },
    {
        label: "POST-WORKOUT",
        value: 4,
    },
];
export const getMealTypeLabel = (value: number) => {
    const option = MealTypeOptions.find((option1) => option1.value === value);
    return option ? option.label : "";
}
export type DietType = {
    label: string;
    value: string;
};
export const DietTypeOptions: DietType[] = [
    {
        label: "Omnivore",
        value: "normalDiet",
    },
    {
        label: "Pescatarian",
        value: "pescetarian",
    },
    {
        label: "Vegetarian",
        value: "vegetarian",
    },
    {
        label: "Vegan",
        value: "vegan",
    },
    {
        label: "Paleo",
        value: "paleo",
    },
    {
        label: "Low Carb",
        value: "low_carb",
    },
    {
        label: "Keto",
        value: "keto",
    }
];
export const getDietTypeLabel = (value: string) => {
    const option = DietTypeOptions.find((option1) => option1.value === value);
    return option ? option.label : "";
}
export type Ingredient = {
    id: number;
    ingredientUnit: number;
    name: string;
    name_en: string;
    translationUnit?: number;
    translationAmmount?: number;
    brand_name?: string;
    protein: number;
    calories: number;
    carbonhydrates: number;
    fat: number;
    fiber: number;
    img: string;
    img_category: string;
    category:number;
}
export type SelectedIngredient = {
    id: number;
    ingredientUnit: number;
    quantity: number;
    savedId: number;
    translationUnit?: number;
    translationAmmount?: number;
    name:string;
    name_en:string;
    brand_name?: string;
    protein?: number;
    calories?: number;
    carbonhydrates?: number;
    fat?: number;
    fiber?: number;
    img?: string;
    img_category?: string;
}
export type IRecipeCreate = {
    title: string;
    title_en: any;

    budget: number;
    difficulty: number;
    prepTime: number;
    mealType: number;

    season_start: string
    season_end: string

    timeCost: string;
    preparation: string;
    preparation_en: any;


    "tenants[0]" : string;
    "basic[0]" : string;
    "basic[1]": string;
    goals: string;

    // selecteddiettype
    advance: string;
    fk_tenant_id: string;
}
export type IRecipeIngredient = {
    id: number;
    fk_ingredientid: number;
    fk_recipeid: number;
    ammount: number;
    limitMax: number;
    limitMin: number;
    steps: number;
    substituable: number;
    created_at: string;
    updated_at: string;
    ingredient? : Ingredient;
};
export type INewIngredient = {
    name: string;
    calories: number;
    protein: number;
    carbonhydrates: number;
    fat: number;
    unit: number;
}
export type IUserRecipe = {
    id: number;
    title: string;
    title_en: string;
    preparation: string;
    preparation_en: string;
    status: string;
    picture: string;
    prepTime: number;
    normalDiet: number;
    difficulty: number;
    calories: number;
    protein: number;
    carb: number;
    fat: number;
    budget:number;
    ingredient_in_recipes: IRecipeIngredient[];
    mealType: number;
    vegetarian: number;
    pescetarian: number;
    keto: number;
    vegan: number;
    paleo: number;
    low_carb: number;
    mealPrep: number;
    notCook: number;
    isFavorite: boolean;

}
